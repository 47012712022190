import React from "react";
import {Modal, Box, Button, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {useContext, useEffect} from "react";
import {ReportDetailsContext} from "src/components/reports/ReportDetails";
import {GenerateReportRequest} from "src/models/reports/GenerateReport";
import GenerateReportsModal from "src/components/reports/GenerateReportsModal";
import useLocalStorage from "src/utils/useLocalStorage";

export default function NoFilterReportModal(props: {
    visible: boolean,
    onClose: () => void,
    reportName: string,
    reportHeader: string
}) {
    const {visible, onClose: onModalClose, reportName, reportHeader} = props;
    const {state, dispatch, services} = useContext(ReportDetailsContext);
    const [showGenerateReportsModal, setShowGenerateReportsModal] = React.useState(false);
    const [reportId, setReportId] = React.useState(null as unknown as string);
    const [generateReportRequest, setGenerateReportRequest] = React.useState(null as unknown as GenerateReportRequest);
    const [generateReportResponse, generateReportLoading, generateReportError] = services.reportsService.generateReport(generateReportRequest);

    useEffect(() => {
        if (!generateReportError) {
            return;
        }
        onModalClose();
        services.messageService.showErrorAutoDismissBanner(generateReportError);
    }, [generateReportError])

    useEffect(() => {
        if (!generateReportResponse) {
            return;
        }
        setReportId(generateReportResponse.reportId);
        setShowGenerateReportsModal(true);
    }, [generateReportResponse])

    return (<>
        <Modal
            onDismiss={onModalClose}
            visible={visible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"  className={"createReportCancelButton"} onClick={onModalClose}>Cancel</Button>
                        <Button variant="primary" className={"createReportButton"} disabled={ generateReportLoading } onClick={() => {
                            setGenerateReportRequest({
                                userId: useLocalStorage.getUsernameFromLocalStorage(),
                                reportName: reportName,
                                params: {}
                            });
                        }}>{generateReportLoading ? 'Creating Report...' : 'Create Report'}</Button>
                    </SpaceBetween>
                </Box>
            }
            header={reportHeader}
        >
            This is a point in time report. No filters are required here. You can go ahead and create the report.
        </Modal>

        <GenerateReportsModal
            visible={showGenerateReportsModal}
            onClose={() => {
                onModalClose();
                setShowGenerateReportsModal(false);
            }}
            reportId={reportId}
        />
    </>
    );
}