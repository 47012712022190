import React from "react";
import {Modal, Select, Box, Button, SpaceBetween, SelectProps} from "@amzn/awsui-components-react/polaris";
import {useContext, useEffect} from "react";
import {ReportDetailsContext} from "src/components/reports/ReportDetails";
import {GenerateReportRequest} from "src/models/reports/GenerateReport";
import GenerateReportsModal from "src/components/reports/GenerateReportsModal";
import {PeriodDropdownOption} from "src/models/reports/GetReportingPeriodListResult";
import Username from "src/utils/useLocalStorage";
import useLocalStorage from "src/utils/useLocalStorage";

export default function PeriodFilterReportModal(props: {
    visible: boolean,
    onClose: () => void,
    reportName: string,
    periodList: PeriodDropdownOption[],
    reportHeader: string

}) {
    const {visible, onClose: onModalClose, reportName, periodList, reportHeader} = props;
    const {state, dispatch, services} = useContext(ReportDetailsContext);
    const [showGenerateReportsModal, setShowGenerateReportsModal] = React.useState(false);
    const [reportId, setReportId] = React.useState(null as unknown as string);
    const [selectedOption, setSelectedOption] = React.useState(null as unknown as SelectProps.Option|null);
    const [generateReportRequest, setGenerateReportRequest] = React.useState(null as unknown as GenerateReportRequest);
    const [generateReportResponse, generateReportLoading, generateReportError] = services.reportsService.generateReport(generateReportRequest);

    useEffect(() => {
        if (!generateReportError) {
            return;
        }
        onModalClose();
        services.messageService.showErrorAutoDismissBanner(generateReportError);
    }, [generateReportError])

    useEffect(() => {
        if (!generateReportResponse) {
            return;
        }
        setReportId(generateReportResponse.reportId);
        setShowGenerateReportsModal(true);
    }, [generateReportResponse])

    return (<>
            <Modal
                onDismiss={onModalClose}
                visible={visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" className={"createReportCancelButton"} onClick={onModalClose}>Cancel</Button>
                            <Button variant="primary" className="createReportButton" disabled={(!selectedOption) || generateReportLoading}
                                    onClick={() => {
                                        setGenerateReportRequest({
                                            userId: useLocalStorage.getUsernameFromLocalStorage(),
                                            reportName: reportName,
                                            params: {
                                                Period: selectedOption?.value as string,
                                            }
                                        });
                                        setSelectedOption(null);
                                    }}>{generateReportLoading ? 'Creating Report...' : 'Create Report'}</Button>
                        </SpaceBetween>
                    </Box>
                }
                header={reportHeader}
            >
                <Select className="periodDropDown"
                    selectedOption={selectedOption}
                    options={periodList}
                    onChange={({detail}) => {
                        setSelectedOption(detail.selectedOption);
                    }}
                    selectedAriaLabel="Selected"
                    placeholder="Select period"
                />
            </Modal>

            <GenerateReportsModal
                visible={showGenerateReportsModal}
                onClose={() =>{
                    onModalClose();
                    setShowGenerateReportsModal(false);
                }}
                reportId={reportId}
            />
        </>
    );
}