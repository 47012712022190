import React from 'react';
import { Container, Header, SpaceBetween } from "@amzn/awsui-components-react";

export default function ReportViewDashboard() {
    return (
        <SpaceBetween size='s'>
            <Container header={
                <Header variant="h2" description="View transfer pricing Tax reports for review, compliance and audit purposes">
                    Transfer Pricing Reports
                </Header>}>
            </Container>
            <Container header={
                <Header variant="h2" description="View accounting reports for review, compliance and audit purposes">
                    Accounting Reports
                </Header>}>
            </Container>
            <Container header={
                <Header variant="h2" description="View  Tax reports for review, compliance and audit purposes">
                    Tax reports
                </Header>}>
            </Container>
        </SpaceBetween>

    )
}