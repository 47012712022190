import React, { useState } from 'react';
import ServiceCollection from "src/services/ServiceCollection";
import { initialState, ReportsState } from 'src/services/reports/ReportsState';
import TPEAction from 'src/models/common/TPEAction';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import { reportsReducer } from 'src/services/reports/ReportsReducer';
import { Button, Container, Grid, Tabs } from '@amzn/awsui-components-react';
import ReportMetricsDashboard from './ReportMetricsDashboard';
import ReportViewDashboard from './ReportViewDashboard';
import TPENavigator from 'src/components/TPENavigator';

export type ContextType = {
    state: ReportsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

export const ReportDashboardContext = React.createContext(null as ContextType | null);


const ReportDashboardProvider = (props: {
    state: ReportsState, dispatch: React.Dispatch<TPEAction>, services: ServiceCollection,
    children: JSX.Element
}) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <ReportDashboardContext.Provider value={providerValue}>
            {children}
        </ReportDashboardContext.Provider>
    );
}
export default function ReportDashboard(props: { services: ServiceCollection }) {
    const { services } = props;
    const [state, dispatch] = useReducerWithLogger(reportsReducer, initialState);
    const [activeTabId, setActiveTabId] = useState('reportsView')

    return (<ReportDashboardProvider state={state} dispatch={dispatch} services={services}>
        <div>
            <Tabs
                onChange={({ detail }) => {
                    setActiveTabId(detail.activeTabId)
                }}
                activeTabId={activeTabId}
                tabs={[
                    {
                        label: 'Metrics',
                        id: 'metrics',
                        content: <ReportMetricsDashboard services={services} />,
                    },
                    {
                        label: 'View Reports',
                        id: 'reportsView',
                        content: <ReportViewDashboard />,
                    }
                ]}
            />

        </div>

    </ReportDashboardProvider>)

};



