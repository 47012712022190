import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    Grid,
    Header,
    Link,
    SelectProps,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris"
import ServiceCollection from "src/services/ServiceCollection";
import CONSTANTS from "src/utils/constants";
import '../reports/ReportsTable.scss';
import TPEAction from "src/models/common/TPEAction";
import NoFilterReportModal from "./NoFilterReportModal";
import PeriodFilterReportModal from "src/components/reports/PeriodFilterReportModal";
import {initialState, ReportsState} from "src/services/reports/ReportsState";
import {PeriodDropdownOption} from "src/models/reports/GetReportingPeriodListResult";
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import {reportsReducer} from "src/services/reports/ReportsReducer";
import TPENavigator from "src/components/TPENavigator";
import TPTaxFilterReportModal from "./TPTaxFilterReportModal";
import TPEErrorWatcher from "../shared/TPEErrorWatcher";

type ContextType = {
    state: ReportsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const ReportDetailsProvider = (props: { state: ReportsState, dispatch: React.Dispatch<TPEAction>, services: ServiceCollection, children: JSX.Element }) => {
    const {state, dispatch, services, children} = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <ReportDetailsContext.Provider value={providerValue}>
            {children}
        </ReportDetailsContext.Provider>
    );
}

export const ReportDetailsContext = React.createContext(null as unknown as ContextType);

export default function ReportDetails(props: { services: ServiceCollection }) {
    const {services} = props;
    const [state, dispatch] = useReducerWithLogger(reportsReducer, initialState);
    const [showPeriodFilterReportModal, setShowPeriodFilterReportModal] = React.useState(false);
    const [showTPTaxMARFilterReportModal, setShowTPTaxMARFilterReportModal] = React.useState(false);
    const [showTPTaxLEFFilterReportModal, setShowTPTaxLEFFilterReportModal] = React.useState(false);
    const [showNoFilterReportModal, setShowNoFilterReportModal] = React.useState(false);
    const [periodList, setPeriodList] = React.useState([] as PeriodDropdownOption[]);
    const [workbookIdDropdownList, setWorkbookIdDropdownList] = React.useState([] as SelectProps.Option[]);
    const [companyCodeDropdownList, setCompanyCodeDropdownList] = React.useState([] as SelectProps.Option[]);
    const [loadTime, setLoadTime] = useState((new Date()).toString());
    const [reportName, setReportName] = React.useState(null as unknown as string);
    const [reportHeader, setReportHeader] = React.useState(null as unknown as string);
    const [navigateURL, setNavigateURL] = React.useState('');
    const [getReportingPeriodsResponse, getReportingPeriodsLoading, getReportingPeriodsError] = services.reportsService.getReportingPeriods();
    const [workbooksListResponse, isWorkbooksDataLoading, workbooksListFetchError] = services.mecService.getWorkbooks(loadTime);
    const [companyCodesListResponse, isCompanyCodesDataLoading, companyCodesFetchError] = services.calculationsService.getCompany(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.WEB_API);
    
    useEffect(() => {
        if (!getReportingPeriodsResponse)
            return;

        setPeriodList([...getReportingPeriodsResponse.ReportingPeriodList.map(value => ({
            label: value,
            value: value
        }))]);

    }, [getReportingPeriodsResponse]);

    useEffect(() => {
        if (!workbooksListResponse) {
            return;
        }
        const updatedWorkbookList: SelectProps.Option[] = [];
        workbooksListResponse.forEach(workbook => {
            const workbookOption: SelectProps.Option = {
                label: workbook.workbookName, 
                value: workbook.workbookId
            };
            updatedWorkbookList.push(workbookOption);
        });

        // Update the state with the new workbook list
        setWorkbookIdDropdownList(updatedWorkbookList);
    }, [workbooksListResponse]);

    useEffect(() => {
        if (! companyCodesListResponse) {
            return;
        }
        const updatedCompanyCodeList: SelectProps.Option[] = [];
        companyCodesListResponse.forEach(companyCode => {
            const companyCodeOption: SelectProps.Option = {
                label: companyCode, 
                value: companyCode
            };
            updatedCompanyCodeList.push(companyCodeOption);
        });

        // Update the state with the new workbook list
        setCompanyCodeDropdownList(updatedCompanyCodeList);
    }, [companyCodesListResponse]);
    
    

    const setReportNameAndReportHeader = (header: string, type: string) => {
        setReportHeader(header);
        setReportName(type);
    }
    return (
        <ReportDetailsProvider services={services} state={state} dispatch={dispatch}>
            <Container className="polaris-content-container">
                <SpaceBetween direction="vertical" size="xs">
                    <div className="reportsTableHeader">
                        <ColumnLayout columns={2} data-class="fullColumnLayout">
                            <Box variant="h2"> <span> Choose ATP Report </span> </Box>
                            <Box float="right">
                                <TPENavigator path={navigateURL}/>
                                <SpaceBetween direction="vertical" size="m">
                                    <Button variant="primary"
                                            onClick={() => setNavigateURL('/reports/history')}>History</Button>
                                </SpaceBetween>
                            </Box>
                        </ColumnLayout>
                    </div>

                    <Grid gridDefinition={[{colspan: 4}, {colspan: 4}, {colspan: 4}]}>
                        <Container
                            header={<Header variant="h2"> TP Accounting Reports </Header>}>
                            <SpaceBetween direction="vertical" size="xs">
                                <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.CALCULATIONS, CONSTANTS.REPORT_TYPES.CALCULATIONS);
                                    setShowPeriodFilterReportModal(true);
                                }}>
                                    <Link> {CONSTANTS.REPORT_HEADERS.CALCULATIONS} </Link>
                                </Button>
                                <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.DATASOURCES, CONSTANTS.REPORT_TYPES.DATASOURCES);
                                    setShowNoFilterReportModal(true);
                                }}>
                                    <Link> {CONSTANTS.REPORT_HEADERS.DATASOURCES} </Link>
                                </Button>
                                <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.CUSTOM_COA, CONSTANTS.REPORT_TYPES.CUSTOM_COA);
                                    setShowNoFilterReportModal(true);
                                }}>
                                    <Link> {CONSTANTS.REPORT_HEADERS.CUSTOM_COA} </Link>
                                </Button>
                                <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.JOURNAL_DETAILS, CONSTANTS.REPORT_TYPES.JOURNAL_DETAILS);
                                    setShowPeriodFilterReportModal(true);
                                }}>
                                    <Link> {CONSTANTS.REPORT_HEADERS.JOURNAL_DETAILS} </Link>
                                </Button>
                                <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.TP_TRENDING.TP_TRENDING_REPORT, CONSTANTS.REPORT_TYPES.TP_TRENDING);
                                }}>
                                    <Link external href={CONSTANTS.ENVIRONMENT_VARIABLES.TP_TRENDING_PORTAL_URI}
                                          target="_blank"> {CONSTANTS.REPORT_HEADERS.TP_TRENDING.TP_TRENDING_REPORT} </Link>
                                </Button>
                                <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.TAX_AGREEMENT_DETAILS, CONSTANTS.REPORT_TYPES.TAX_AGREEMENT_DETAILS);
                                    setShowNoFilterReportModal(true);
                                }}>
                                    <Link> {CONSTANTS.REPORT_HEADERS.TAX_AGREEMENT_DETAILS} </Link>
                                </Button>
                                <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.COA_CHANGE_MANAGEMENT, CONSTANTS.REPORT_TYPES.COA_CHANGE_MANAGEMENT);
                                    setShowNoFilterReportModal(true);
                                }}>
                                    <Link> {CONSTANTS.REPORT_HEADERS.COA_CHANGE_MANAGEMENT} </Link>
                                </Button>
                                <Button variant="link" onClick={() => setNavigateURL('/reports/prevalidation')}>
                                    <Link> Pre-Validation Reports </Link>
                                </Button>
                                <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.ALLOCATION_TRENDING, CONSTANTS.REPORT_TYPES.ALLOCATION_TRENDING);
                                    setShowPeriodFilterReportModal(true);
                                }}>
                                    <Link> {CONSTANTS.REPORT_HEADERS.ALLOCATION_TRENDING} </Link>
                                </Button>
                                <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.FORMULA_SET, CONSTANTS.REPORT_TYPES.FORMULA_SET);
                                    setShowNoFilterReportModal(true);
                                }}>
                                    <Link> {CONSTANTS.REPORT_HEADERS.FORMULA_SET} </Link>
                                </Button>
                                <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.CALCULATION_CHANGE, CONSTANTS.REPORT_TYPES.CALCULATION_CHANGE);
                                    setShowNoFilterReportModal(true);
                                }}>
                                    <Link> {CONSTANTS.REPORT_HEADERS.CALCULATION_CHANGE} </Link>
                                </Button>
                                <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.CUSTOM_DATA_AUTO_INSERT, CONSTANTS.REPORT_TYPES.CUSTOM_DATA_AUTO_INSERT);
                                    setShowPeriodFilterReportModal(true);
                                }}>
                                    <Link> {CONSTANTS.REPORT_HEADERS.CUSTOM_DATA_AUTO_INSERT} </Link>
                                </Button>
                            </SpaceBetween>

                            <PeriodFilterReportModal
                                visible={showPeriodFilterReportModal}
                                onClose={() => setShowPeriodFilterReportModal(false)}
                                reportName={reportName}
                                periodList={periodList}
                                reportHeader={reportHeader}
                            />

                            <NoFilterReportModal
                                visible={showNoFilterReportModal}
                                onClose={() => setShowNoFilterReportModal(false)}
                                reportName={reportName}
                                reportHeader={reportHeader}
                            />
                        </Container>

                        <Container
                            header={<Header variant="h2"> TP Tax Reports </Header>}>
                            <SpaceBetween direction="vertical" size="xs">
                            <Button variant="link" onClick={() => { setNavigateURL('/reports/tpTaxVarianceRecords') }}>
                                <Link> {CONSTANTS.REPORT_HEADERS.TP_TAX_VARIANCE} </Link>
                            </Button>
                            <Button variant="link" onClick={() => { setNavigateURL('/reports/tpTaxReport') }}>
                                <Link> {CONSTANTS.REPORT_HEADERS.TP_TAX} </Link>
                            </Button>
                            <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.TP_TAX_CWB_REPORT, CONSTANTS.REPORT_TYPES.TP_TAX_CWB_REPORT);
                                    setShowTPTaxMARFilterReportModal(true);}}>
                                <Link> {CONSTANTS.REPORT_HEADERS.TP_TAX_CWB_REPORT} </Link>
                            </Button>
                            <Button variant="link" onClick={() => {
                                    setReportNameAndReportHeader(CONSTANTS.REPORT_HEADERS.TP_TAX_LEF_RPORT, CONSTANTS.REPORT_TYPES.TP_TAX_LEF_REPORT);
                                    setShowTPTaxLEFFilterReportModal(true);}}>
                                <Link> {CONSTANTS.REPORT_HEADERS.TP_TAX_LEF_RPORT} </Link>
                            </Button>
                            </SpaceBetween>
                            <TPTaxFilterReportModal
                                visible={showTPTaxMARFilterReportModal}
                                onClose={() => setShowTPTaxMARFilterReportModal(false)}
                                reportName={reportName}
                                periodList={periodList}
                                reportHeader={reportHeader}
                                workbookList={workbookIdDropdownList}
                            />
                            <TPTaxFilterReportModal
                                visible={showTPTaxLEFFilterReportModal}
                                onClose={() => setShowTPTaxLEFFilterReportModal(false)}
                                reportName={reportName}
                                periodList={periodList}
                                reportHeader={reportHeader}
                                companyCodeList={companyCodeDropdownList}
                            />
                        </Container>

                        <Container
                            header={<Header variant="h2"> Indirect Tax Reports </Header>}>
                            <SpaceBetween direction="vertical" size="xs">
                                <Button variant="link" onClick={() => { setNavigateURL('/reports/taxAuditRecord') }}>
                                   <Link> {CONSTANTS.REPORT_HEADERS.TAX_AUDIT_RECORD} </Link>
                                </Button>
                            </SpaceBetween>
                        </Container>
                    </Grid>
                </SpaceBetween>
                <TPEErrorWatcher services={services} errors={[getReportingPeriodsError, workbooksListFetchError, companyCodesFetchError]} />
            </Container>
        </ReportDetailsProvider>
    );
}