import React from "react";
import {Modal, Select, Box, Button, SpaceBetween, SelectProps} from "@amzn/awsui-components-react/polaris";
import {useContext, useEffect} from "react";
import {ReportDetailsContext} from "src/components/reports/ReportDetails";
import {GenerateReportRequest} from "src/models/reports/GenerateReport";
import GenerateReportsModal from "src/components/reports/GenerateReportsModal";
import {accountingDaysList} from "src/models/reports/GetAccountingDayList"
import useLocalStorage from "src/utils/useLocalStorage";
import TPEErrorWatcher from 'src/components/shared/TPEErrorWatcher';

export default function TPTaxFilterReportModal(props: {
    visible: boolean,
    onClose: () => void,
    reportName: string,
    workbookList?: SelectProps.Option[],
    companyCodeList?: SelectProps.Option[],
    periodList: SelectProps.Option[],
    reportHeader: string

}) {
    const {visible, onClose: onModalClose, reportName, workbookList,companyCodeList, periodList, reportHeader} = props;
    const {state, dispatch, services} = useContext(ReportDetailsContext);
    const [showGenerateReportsModal, setShowGenerateReportsModal] = React.useState(false);
    const [reportId, setReportId] = React.useState(null as unknown as string);
    const [selectedPeriodOption, setSelectedPeriodOption] = React.useState(null as unknown as SelectProps.Option|null);
    const [selectedWorkbookIdOption, setSelectedWorkbookIdOption] = React.useState(null as unknown as SelectProps.Option|null);
    const [selectedCompanyCodeOption, setSelectedCompanyCodeOption] = React.useState(null as unknown as SelectProps.Option|null);
    const [selectedAccountingDayOption, setSelectedAccountingDayOption] = React.useState(null as unknown as SelectProps.Option|null);
    const [generateReportRequest, setGenerateReportRequest] = React.useState(null as unknown as GenerateReportRequest);
    const [generateReportResponse, generateReportLoading, generateReportError] = services.reportsService.generateReport(generateReportRequest);

    useEffect(() => {
        if (!generateReportResponse) {
            return;
        }
        setReportId(generateReportResponse.reportId);
        setShowGenerateReportsModal(true);
    }, [generateReportResponse])

    return (<>
            <Modal
                onDismiss={onModalClose}
                visible={visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" data-class = {"saveButton createReportCancelButton"} onClick={onModalClose}>Cancel</Button>
                            <Button variant="primary" data-class = "saveButton createReportButton" disabled={!selectedPeriodOption || 
                                                                                               !!generateReportLoading ||  
                                                                                               !selectedAccountingDayOption || 
                                                                                               (!!workbookList && !selectedWorkbookIdOption) ||
                                                                                               (!!companyCodeList && !selectedCompanyCodeOption)
                                                                                            }
                                    onClick={() => {
                                        setGenerateReportRequest({
                                            userId: useLocalStorage.getUsernameFromLocalStorage(),
                                            reportName: reportName,
                                            params: {
                                                period: selectedPeriodOption?.value as string,
                                                accountingDay: selectedAccountingDayOption?.value as string,
                                                workbookId: selectedWorkbookIdOption?.value as string,
                                                providerCompanyCode: selectedCompanyCodeOption?.value as string
                                            }
                                        });
                                        setSelectedPeriodOption(null);
                                        setSelectedAccountingDayOption(null);
                                        setSelectedWorkbookIdOption(null);
                                        setSelectedCompanyCodeOption(null);
                                    }}>{generateReportLoading ? 'Creating Report...' : 'Create Report'}</Button>
                        </SpaceBetween>
                    </Box>
                }
                header={reportHeader}
            >
            <SpaceBetween direction="vertical" size="xs">
                <Select data-class = "basicModalDropdown periodDropDown"
                    selectedOption={selectedPeriodOption}
                    options={periodList}
                    onChange={({detail}) => {
                        setSelectedPeriodOption(detail.selectedOption);
                    }}
                    selectedAriaLabel="Selected"
                    placeholder="Select Period"
                />
                <Select data-class = "basicModalDropdown accountingDayDropDown"
                    selectedOption={selectedAccountingDayOption}
                    options={accountingDaysList}
                    onChange={({detail}) => {
                        setSelectedAccountingDayOption(detail.selectedOption);
                    }}
                    selectedAriaLabel="Selected"
                    placeholder="Select Accounting Day"
                />
                {workbookList && 
                    <Select data-class = "basicModalDropdown workbookListDropDown"
                    selectedOption={selectedWorkbookIdOption}
                    options={workbookList}
                    onChange={({detail}) => {
                        setSelectedWorkbookIdOption(detail.selectedOption);
                    }}
                    loadingText="Loading Workbooks List"
                    filteringType="auto"
                    filteringPlaceholder="Search Workbook Name"
                    selectedAriaLabel="Selected"
                    placeholder="Select Workbook Id"
                />

                }
                {companyCodeList && 
                    <Select data-class = "basicModalDropdown companyCodeListDropdown"
                    selectedOption={selectedCompanyCodeOption}
                    options={companyCodeList}
                    onChange={({detail}) => {
                        setSelectedCompanyCodeOption(detail.selectedOption);
                    }}
                    loadingText="Loading Company Codes"
                    filteringType="auto"
                    filteringPlaceholder="Search Company Code"
                    selectedAriaLabel="Selected"
                    placeholder="Select Company Code"
                />

                }
            </SpaceBetween>
            <TPEErrorWatcher services={services} errors={[generateReportError]} />
            </Modal>

            <GenerateReportsModal
                visible={showGenerateReportsModal}
                onClose={() =>{
                    onModalClose();
                    setShowGenerateReportsModal(false);
                }}
                reportId={reportId}
            />
        </>
    );
}